/** 活动管理 */
import $axios from "../common/http";

/**   @ activity/banner/index  获取课时列表
 * 
 */
export function getBannerList(data) {
    let res = $axios.get(`S9vC7rYOo4.php/activity/banner/index?page=1&row=100`);
    return res;
}
/**   @ activity/banner/add  添加课时列表
 * 
 */
export function addBannerItem(data) {
    let res = $axios.post(`S9vC7rYOo4.php/activity/banner/add`, data);
    return res;
}
/**   @ activity/banner/index  获取单个课时列表
 * 
 */
export function getBannerItem(data) {
    let res = $axios.get(`S9vC7rYOo4.php/activity/banner/edit?id=${data}`);
    return res;
}
/**   @ activity/banner/edit  修改课时列表
 * 
 */
export function editBannerItem(data) {
    let res = $axios.post(`S9vC7rYOo4.php/activity/banner/edit`, data);
    return res;
}
/**   @ activity/banner/del  删除课时列表
 * 
 */
export function delBannerItem(data) {
    let res = $axios.post(`S9vC7rYOo4.php/activity/banner/del?ids=${data}`);
    return res;
}
import axios from "axios"
import router from "../router/index"
/** baseURl 在main.js里进行统一修改  会自动生成当为生产模式-baseURl-http://m.kexuemihe.com            
 * 开发模式-baseURl-http://139.186.155.167    */
let $axios = axios.create({
    // 基准前缀地址！  //http://139.186.155.167 测试服务器   //http://m.kexuemihe.com
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 100000 // 请求时长！
})
// 添加请求拦截器
$axios.interceptors.request.use(function (config) {
    
    console.log(config.url == 'S9vC7rYOo4.php/auth/admin/reg')
    if (config.url == 'S9vC7rYOo4.php/index/login' || config.url.includes('S9vC7rYOo4.php/index/send') || config.url == 'S9vC7rYOo4.php/auth/admin/reg') { // 登录请求！  如果发送的是登录请求则放行
        return config;
    } else {      
        let userinfo = JSON.parse(localStorage.getItem("userinfo"))
        // 请求头里面携带上token!   具体的字段名要和后端沟通！
        if (userinfo) {
            config.headers.Authorization = userinfo.jwt; //authorization 这个字段名要与后台沟通
            // config.headers.token = 'a5bf96f7-805a-4885-a2fb-00fb2763db0a'; //authorization 这个字段名要与后台沟通
        } else {
            router.replace('/login');
        }
    }
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
$axios.interceptors.response.use(function (response) {
    // console.log(response);
    // 对数据进行处理
    if (response.data.msg == "请登录后操作") { // 状态为403表示jwt过期，需要重新登录！    当jwt无效后，后端返回的msg字段为   请登录后操作   
        localStorage.removeItem("userinfo");
        router.replace('/login');
        return response.data;
    } else {
        return response.data;
    }
}, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});

// 暴露axios实例！
export default $axios;
/**  常规管理 - 个人资料*/
import $axios from "@/common/http";

/**   @ general/profile/index  获取当前登录用户的操作列表
 * 
 */
export function getProfileList(data) {
    let res = $axios.get(`S9vC7rYOo4.php/general/profile/index?page=${data.page}&limit=${data.size}&offset=${data.offset}`);
    return res;
}
/**   @ general/profile/update  修改自己的登录密码
 * 
 */
export function editUserPwd(data) {
    let res = $axios.post(`S9vC7rYOo4.php/general/profile/update`, data);
    return res;
}
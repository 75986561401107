/** 精课管理 - 教师端课程管理 */
import $axios from '../common/http';

/**  info/course/weigh  根据课程id修改课程的权重weigh
 *   post   @param : id 课程id     weigh 课程权重
 */
export function editCourseWeigh(data) {
    let res = $axios.post(`S9vC7rYOo4.php/info/course/weigh`, data);
    return res;
}
/**   返回机构列表 和 课程类型列表
 *  
 */
export function getOrganList(data) {
    let res = $axios.get(`S9vC7rYOo4.php/info/course/info`);
    return res;
}
/*** 获取课程列表
 *  @params ： 
 */
export function getCourseList(data) {
    let res = $axios.get(`S9vC7rYOo4.php/info/course/index?page=${data.page}&limit=${data.size}&offset=${data.offset}`);
    return res;
}
/*** 获取单个课程列表
 *  @params ： 
 */
export function getOneCourseList(data) {
    let res = $axios.get(`S9vC7rYOo4.php/info/course/edit?id=${data.id}`);
    return res;
}
/*** 查找课时
 *  @params ： 
 */
export function searchCouHour(data) {
    let res = $axios.get(`S9vC7rYOo4.php/info/hour/index?filter=${JSON.stringify(data)}&op={"name":"LIKE"}`);
    return res;
}
/*** 查找课程 : 11-15 废弃
 *  @params ： 
 */
// export function searchCourse(data) { //sort  order的值不要加双引    asc为顺序
//     // console.log(data);
//     let info = {
//         [data.filterName]: data.val,
//     }
//     let res = $axios.get(`S9vC7rYOo4.php/info/course/index?filter=${JSON.stringify(info)}&op={"${data.filterName}":"LIKE"}&sort=weigh&order=asc`);
//     return res;
// }

/*** 获取课时列表
 *  @params ： 
 */
export function getCouHourList(data) {
    let res = $axios.get(`S9vC7rYOo4.php/info/hour/index?page=${data.page}&limit=${data.size}&offset=${data.offset}`);
    return res;
}
/*** 获取单个课时列表
 *  @params ： 
 */
export function getOneCouHourList(data) {
    let res = $axios.get(`S9vC7rYOo4.php/info/hour/edit?id=${data.id}`);
    return res;
}
/***   添加课程类型
 *  @params ：name coursetype tag orgs content images weigh  hour_ids 
 */
export function addCourseType(data) {
    let res = $axios.post(`S9vC7rYOo4.php/info/ctype/add`, data);
    return res;
}
/***   添加课程
 *  @params ：name
 */
export function addCourse(data) {
    let res = $axios.post(`S9vC7rYOo4.php/info/course/add`, data);
    return res;
}
/***   添加课时 / ppt
 *  @params ：name content cover_img hourtype weigh resource_ids(课时id数据)
 */
export function addCouHour(data) {
    let res = $axios.post(`S9vC7rYOo4.php/info/hour/add`, data);
    return res;
}
/**
 * 添加课件
 * @params : 
 */
export async function addCourseware(data) {
    // console.log(data);
    let res = $axios.post(`S9vC7rYOo4.php/info/resource/add`, data);
    return res;
}
/***   修改单个课程类型
 *  @params ：ids name
 */
export function editCourseType(data) {
    let res = $axios.post(`S9vC7rYOo4.php/info/ctype/edit`, data);
    return res;
}
/***   修改单个课程信息
 *  @params ：ids name
 */
export function editCourse(data) {
    let res = $axios.post(`S9vC7rYOo4.php/info/course/edit`, data);
    return res;
}
/***   修改单个课时信息
 *  @params ：ids name
 */
export function editCouHour(data) {
    let res = $axios.post(`S9vC7rYOo4.php/info/hour/edit`, data);
    return res;
}
/***   删除单个课程类型
 *  @params ：ids 
 */
export function delCourseType(data) {
    let res = $axios.post(`S9vC7rYOo4.php/info/ctype/del`, data);
    return res;
}

/***   删除单个课程(包括其下课时课件)
 *  @params ：ids 
 */
export function deleteCourse(data) {
    let res = $axios.get(`S9vC7rYOo4.php/info/course/delete?ids=${data.ids}`);
    return res;
}
/***   删除单个课时
 *  @params ：ids 
 */
export function delCourseHour(data) {
    let res = $axios.get(`S9vC7rYOo4.php/info/hour/del?ids=${data.ids}`);
    return res;
}
/**
 * 删除单个课件
 * @params : ids
 */
export async function delCourseware(data) {
    // console.log(data);
    let res = $axios.get(`S9vC7rYOo4.php/info/resource/del?ids=${data.ids}`);
    return res;
}


/**
 * 复制课程
 * @params : org_id：机构id    cid：被复制的课程id
 */
export async function copyCourse(data) {
    // console.log(data);
    let res = $axios.get(`S9vC7rYOo4.php/info/course/copy?cid=${data.cid}&org_id=${data.org_id}`);
    // let res = $axios.post(`S9vC7rYOo4.php/info/course/copy`,data);
    return res;
}

/**
 * 通过课件id获取课件信息
 * @params : 课件id
 */
export async function getOneCourseware(data) {
    // console.log(data);
    let res = $axios.get(`S9vC7rYOo4.php/info/resource/edit?id=${data}`);
    return res;
}


/**
 * 通过课件id修改课件信息
 * @params : 
 */
export async function modifyCourseware(data) {
    // console.log(data);
    let res = $axios.post(`S9vC7rYOo4.php/info/resource/edit`, data);
    return res;
}
/**
 * 修改课时解锁
 * @params : cid-课程id      hid-课时id
 */
export async function modifyIslock(data) {
    // console.log(data);
    let res = $axios.get(`S9vC7rYOo4.php/info/course/lock?cid=${data.cid}&hid=${data.hid}`);
    return res;
}
/** 标签管理 */
import $axios from "../../common/http";

/**   @ 获取标签列表
 * 
 */
export function getStuTagsList(data) {
    let res = $axios.get(`S9vC7rYOo4.php/client/tags/index?page=1&row=100`);
    return res;
}


/**   @ 修改标签列表
 * 
 */
export function getStuTags(data) {
    let res = $axios.get(`S9vC7rYOo4.php/client/tags/edit?id=${data}`);
    return res;
}
/**   @ 修改标签列表
 * 
 */
export function editStuTags(data) {
    let res = $axios.post(`S9vC7rYOo4.php/client/tags/edit`, data);
    return res;
}
/**   @ 删除标签列表
 * 
 */
export function delStuTags(data) {
    let res = $axios.post(`S9vC7rYOo4.php/client/tags/del`, data);
    return res;
}



/** 课程管理 */
import $axios from "../../common/http";

/*** 查找课时
 *  @params ： 
 */
export function searchStuCou(data) {
    let res = $axios.get(`S9vC7rYOo4.php/client/course/index?filter=${JSON.stringify(data)}&op={"name":"LIKE"}&page=${data.page}&limit=${data.size}`);
    return res;
}
/**   @ 获取课程列表
 * 
 */
export function getStuCourseList(data,filter) {
    let res = $axios.get(`S9vC7rYOo4.php/client/course/index?page=${data.page}&limit=${data.size}&filter=${JSON.stringify(filter)}&op={"name":"LIKE"}`);
    return res;
}

/**   @ 获取课程列表 Pro
 * 
 */
export function getCourse(data) {
    let res = $axios.get(`S9vC7rYOo4.php/client/course/index`,{ params: data });
    return res;
}

/**   @ 添加课程列表
 * 
 */
export function addStuCourse(data) {
    let res = $axios.post(`S9vC7rYOo4.php/client/course/add`, data);
    return res;
}
/**   @ 添加课程列表
 * 
 */
export function getStuCourse(data) {
    let res = $axios.get(`S9vC7rYOo4.php/client/course/edit?id=${data}`);
    return res;
}

/**   @ 修改课程列表
 * 
 */
export function editStuCourse(data) {
    let res = $axios.post(`S9vC7rYOo4.php/client/course/edit`, data);
    return res;
}
/**   @ 删除课程列表
 * 
 */
export function delStuCourse(data) {
    let res = $axios.post(`S9vC7rYOo4.php/client/course/del`, data);
    return res;
}
/**   @ 生成二维码
 * 
 */
export function createQrCode(data) {
    let res = $axios.get(`S9vC7rYOo4.php/client/course/qrcode?id=${data}`);
    return res;
}

/** 教师端用户管理 - 人员管理 */
import $axios from "../common/http";

/**   @ user/teacher/index  获取用户列表
 * 
 */
export function getTeacherUserList(data) {
    let res = $axios.get(`S9vC7rYOo4.php/user/teacher/index?page=${data.page}&limit=${data.size}&offset=${data.offset}`);
    return res;
}
/**   @ user/teacher/index  查找用户
 * 
 */
export function searchTeachUser(data) {
    let res = $axios.get(`S9vC7rYOo4.php/user/teacher/index?filter=${JSON.stringify(data)}&op={"mobile":"LIKE"}`);
    return res;
}
/**   @ user/teacher/add  添加用户列表
 * 
 */
export function addTeacherUserItem(data) {
    let res = $axios.post(`S9vC7rYOo4.php/user/teacher/add`, data);
    return res;
}
/**   @ user/teacher/index  获取单个用户列表
 * 
 */
export function getTeacherUserItem(data) {
    let res = $axios.get(`S9vC7rYOo4.php/user/teacher/edit?id=${data}`);
    return res;
}
/**   @ user/teacher/edit  修改用户列表
 * 
 */
export function editTeacherUserItem(data) {
    let res = $axios.post(`S9vC7rYOo4.php/user/teacher/edit`, data);
    return res;
}
/**   @ user/teacher/del  删除用户列表
 * 
 */
export function delTeacherUserItem(data) {
    let res = $axios.post(`S9vC7rYOo4.php/user/teacher/del?ids=${data}`);
    return res;
}
/* 互动游戏模块接口  通用  */
import $axios from "../common/http";

/**
 * 保存课件（所有type类型的课件）    / 　课时保存接口 每个课时上传保存所用接口
 * 
 */
export async function saveInteract(data) {
    let res = await $axios.post("S9vC7rYOo4.php/small/resource/add", data);
    return res;
}
/**  1 视频 2 图片 3 音频 4 图片目录 5 文字 6 动态 7 判断  8 选择  9 拖拽  10 标注 11 音频跟读  12ai互动组合
 * 通过type类型，获取不同的type类型数据
 */
export async function getInterGame(data) {
    let res = $axios.get(`S9vC7rYOo4.php/small/resource/type?type=${data.type}&page=${data.page}&row=${data.row}&org_id=${data.org_id}`);
    return res;
}
/**
 *  功能 获取type为 7 8 9 10 11所有的ai互动课件
 */
export async function getAllInter(data) {
    let res = $axios.get(`S9vC7rYOo4.php/small/resource/game_type?page=${data.page}&row=${data.row}`);
    return res;
}
/**
 *  功能 课时保存
 */
export async function saveCouHour(data) {
    let res = $axios.post(`S9vC7rYOo4.php/small/hour/add`, data);
    return res;
}
/**
 * 通过id，获取单个的ai互动
 * 
 */
export async function getOneInterGame(data) {
    let res = $axios.get(`S9vC7rYOo4.php/small/resource/edit?id=${data.id}`);
    return res;
}
/**
 *  获取目前所有的课程类型（其课程类型不是微课的课时类型，对于这里无用）
 */
export async function getHourType() {
    let res = $axios.get(`S9vC7rYOo4.php/small/hour/type`);
    return res;
}
/** 修改课件
 *  @param: id 
 */
export async function editResource(data) {
    let res = $axios.post(`S9vC7rYOo4.php/small/resource/edit`, data);
    return res;
}
/**
 *      删除ai课件  
 *   参数 @ids:课件id   多个就用数组
 */
export async function delCourse(data) {
    let ids = {
        ids: data.toString() //将数组转换成字符串
    }
    let res = $axios.post(`S9vC7rYOo4.php/small/resource/del_resource`, ids);
    return res;
}
// 角色组规则
import {
    getUserGroupList
} from "../../request/userGroupManage"

export default {
    namespaced: true,
    state: {
        list: [], // 列表信息
    },
    getters: {
        list: state => state.list,
    },
    mutations: {
        SET_LIST(state, data) {
            state.list = data
        },
    },
    actions: {
        async get_list({
            commit,
            state
        }) {
            let data = null;
            if (state.page == 1) {
                data = {
                    page: state.page,
                    size: state.size,
                    offset: 0
                }
            } else {
                data = {
                    page: state.page,
                    size: state.size,
                    offset: (state.page - 1) * state.size
                }
            }
            let res = await getUserGroupList();
            commit('SET_LIST', res.rows);
        },
    }
}
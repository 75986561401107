/** 客服 */
import $axios from "../../common/http";
/*** 查找客服
 *  @params ： 
 */
export function searchCustomerService(data) {
    let res = $axios.get(`S9vC7rYOo4.php/user/usermanager/index?filter=${JSON.stringify(data)}&op={"username":"LIKE"}`);
    return res;
}
/**   @ 获取客服列表
 * 
 */
export function getCustomerServiceList(data) {
    let res = $axios.get(`S9vC7rYOo4.php/user/usermanager/index?page=${data.page}&limit=${data.size}`);
    return res;
}

/**   @ 添加客服列表
 * 
 */
export function addCustomerService(data) {
    let res = $axios.post(`S9vC7rYOo4.php/user/usermanager/add`, data);
    return res;
}

/**   @ 获取客服信息
 * 
 */
export function getCustomerService(data) {
    let res = $axios.post(`S9vC7rYOo4.php/user/usermanager/ManagerInfo`,data);
    return res;
}
/**   @ 修改客服列表
 * 
 */
export function editCustomerService(data) {
    let res = $axios.post(`S9vC7rYOo4.php/user/usermanager/edit`, data);
    return res;
}
/**   @ 删除客服列表
 * 
 */
export function delCustomerService(data) {
    let res = $axios.post(`S9vC7rYOo4.php/user/usermanager/del`, data);
    return res;
}

export default {
    namespaced: true,
    state: {
        revocationArr: [], // 撤销数据
    },
    getters: {
        revocationArr: state => state.revocationArr,
    },
    mutations: {
        SET_revocationArr(state, data) {
            state.revocationArr = data
        },
    },
    actions: {}
}
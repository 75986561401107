import $axios from "../../common/http";
// 微课用户管理接口 - 老师管理
/**  -------------------------------------------------老师人员管理接口 */
/**   @ small/teacher/index  获取列表
 * 
 */
export function getLitterTeacherList() {
    let res = $axios.get(`S9vC7rYOo4.php/small/teacher/index`);
    return res;
}
/**   @ small/teacher/add  添加老师   此老师相当于直接添加了权限管理员 
 * 
 */
export function addLitterTeacherItem(data) {
    let res = $axios.post(`S9vC7rYOo4.php/small/teacher/add`, data);
    return res;
}
/**   @ small/teacher/edit  修改列表
 * 
 */
export function editLitterTeacherItem(data) {
    let res = $axios.post(`S9vC7rYOo4.php/small/teacher/edit`, data);
    return res;
}
/**   @ small/teacher/edit  获取列表
 * 
 */
export function getLitterTeacherItem(data) {
    let res = $axios.get(`S9vC7rYOo4.php/small/teacher/edit?id=${data}`);
    return res;
}
/**   @ small/teacher/del  删除列表
 * 
 */
export function delLitterTeacherItem(data) {
    let res = $axios.post(`S9vC7rYOo4.php/small/teacher/del`,{ids:data});
    return res;
}
/**   @ small/teacher/student  获取某个老师下管理的学生列表
 * 
 */
export function getLitterTeacherBtmStudentList(data) {
    let res = $axios.get(`S9vC7rYOo4.php/small/teacher/student?id=${data}`);
    return res;
}
/**   @ small/teacher/student  获取某个老师下管理的学生课程信息
 * 
 */
export function getLitterTeacherBtmStudentInfo(data) {
    let res = $axios.get(`S9vC7rYOo4.php/small/teacher/info?sid=${data}`);
    return res;
}

/** 老师人员管理接口 -------------------------------------------------- */
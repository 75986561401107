import Vue from 'vue'
import VueRouter from 'vue-router'

import {
  getPermissionList, //获取权限的接口
} from '../request/index'
Vue.use(VueRouter);

// //解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
//push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

const router = new VueRouter({
  // linkActiveClass: "", // 模糊
  // linkExactActiveClass: "class名", // 严格
  // { path: "/地址", name:"Home", component: () => import('../views/Home.vue'), meta:{key:val,key2:val2} },
  routes: [{
    path: "/login",
    name: "Login",
    // component: () => import('@/views/Login.vue'),
    component: resolve => require(["@/views/Login.vue"], resolve),
    meta: {
      title: "登录"
    }
  },
  {
    path: "/signin",
    name: "signin",
    component: resolve => require(["@/views/Signin.vue"], resolve),
    meta: {
      title: "注册"
    }
  },
  {
    path: "/",
    name: "Index",
    component: () => import('@/views/Index.vue'),
    meta: {
      title: "AI智能教育平台"
    },
  },
  {
    path: "/visualEditer",
    name: "visualEditer",
    component: () => import('@/views/studentManage/prepareCourse/visualEditer.vue'),
    meta: {
      title: "可视化编辑PPT"
    },
  },{
    path: "/PPTEditer",
    name: "PPTEditer",
    component: () => import('@/views/PPTEditer/index.vue'),
    meta: {
      title: "PPT可视化编辑"
    },
  },
  {
    path: "/personalData",
    name: "personalData",
    component: () => import('@/views/commonOptManage/personalData/index'),
    meta: {
      title: "个人信息"
    },
  },
  // {
  //   path: "/prepareCourse",
  //   name: "prepareCourse",
  //   component: () => import('@/views/visualEditer/index.vue'),
  //   meta: {
  //     title: "备课模式"
  //   },
  // },
  {
    path: "/createpage",
    name: "createpage",
    component: () => import('@/views/createcontent/createpage/index.vue'),
    meta: {
      title: "创作内容"
    },
  },
  {
    path: "/prepareCourse",
    name: "prepareCourse",
    component: () => import('@/views/studentManage/prepareCourse/index.vue'),
    meta: {
      title: "备课模式"
    },
  },
  {
    path: "/nonePermission",
    name: "nonePermission",
    component: () => import('@/views/nonePermission.vue'),
    meta: {
      title: "权限错误提示"
    },
  }
  ]
})

let routesArr = [];

/** 解决webpack  打包es6语法import时，引入不能是变量的问题 */
function lazyLoad(url) {
  return () => import(`@/${url}`)
}

function getRoutes(permissionList) {
  permissionList.forEach((item, i) => {
    // if (item.status == "hidden") {
    //   return false;
    // }
    let obj;
    let url = `views/${item.url}`;
    if (item.ismenu == 1) { // ismenu 为1的是菜单才可以有对应的路由    为0则是按钮不会有对应路由地址
      if (item.url) { //证明是二级菜单或以下需要注册路由
        obj = {
          path: item.name,
          component: lazyLoad(url),
          // component: () => import(`@/views/permissionManage/menuManage/`),
          meta: {
            title: item.title
          }
        }
        routesArr.push(obj);
      }
      if (item.children) {
        getRoutes(item.children);
        return false;
      };
      return false;
    }
  });
  return routesArr
}
router.beforeEach(async (to, from, next) => {
 
  document.title = to.meta.title; //进入路由前,将此路由的浏览器标题改为meta的title字段
  if (to.name === 'Login' || to.name === 'signin' ) { //如果是登录页，则跳过验证
    next(); //必不可少
    return //以下的代码不执行
  }
  let userinfo = JSON.parse(localStorage.getItem("userinfo"));
  if (!userinfo) {
    console.log("路由守卫--------------");
    next("/login");
  }
  sessionStorage.setItem("curRouter", to.fullPath); // to.fullPath == /路由
  if (router.app.$options.store.state.permissionArr.length == 0) {
    // router.app.$options.store._actions.get_PermissionList[0]();
    let res = await getPermissionList();
    // console.log(res);
    if (res.data.row.length <= 0 || res.code == 0) {
      return false;
    }
    router.app.$options.store.state.permissionArr = res.data.row.filter(item=>item.id != 385);//过滤id==385的shopro商城并加入缓存
    // console.log(router.app.$options.store.state.permissionArr);
    getRoutes(res.data.row);
    routesArr.forEach(item => {
      if (item.path == "/") { //证明是首页Index.vue(Index.vue里面是放tab  header的组件)  所以所有的路由页面都需要时Index.vue的子路由
        // router.addRoute(item);
        return false;
      } else {
        router.addRoute("Index", item); //router.addRoute("父路由的name值",子路由的配置对象)
      }
    })

    router.addRoute({ //404页面需要放在路由注册的最后面
      path: "*",
      component: () => import("@/views/404.vue"),
      meta: {
        title: "404页面"
      }
    })
    let curRouter = sessionStorage.getItem("curRouter");
    // 主要目的：刷新页面后，直接跳转到上次的路由
    console.log(curRouter);
    if (curRouter) {
      next(curRouter);
      return false;
    }
    next("/");
  }
  next();
})
console.log('路由数据',router)
export default router


/**
 *  // children: [{
      //     path: "/adminManage",
      //     name: "adminManage",
      //     component: () => import('@/views/permissionManage/adminManage'),
      //     meta: {
      //       title: "管理员管理"
      //     }
      //   }, {
      //     path: "/adminLog",
      //     name: "adminLog",
      //     component: () => import('@/views/permissionManage/adminLog'),
      //     meta: {
      //       title: "管理员日志"
      //     }
      //   }, {
      //     path: "/userGroupManage",
      //     name: "userGroupManage",
      //     component: () => import('@/views/permissionManage/userGroupManage'),
      //     meta: {
      //       title: "角色组"
      //     }
      //   }, {
      //     path: "/menuManage",
      //     name: "menuManage",
      //     component: () => import('@/views/permissionManage/menuManage'),
      //     meta: {
      //       title: "菜单规则"
      //     }
      //   }, {
      //     path: "/telVideoInteract",
      //     name: "TelVideoInteract",
      //     component: () => import('@/views/InteractGame/telVideoInteract'),
      //     meta: {
      //       title: "微课互动视频"
      //     }
      //   },
      //   {
      //     path: "/selectInteract",
      //     name: "SelectInteract",
      //     component: () => import('@/views/InteractGame/selectInteract'),
      //     meta: {
      //       title: "选择互动"
      //     }
      //   },
      //   {
      //     path: "/dragInteract",
      //     name: "DragInteract",
      //     component: () => import('@/views/InteractGame/dragInteract'),
      //     meta: {
      //       title: "拖拽互动"
      //     }
      //   },
      //   {
      //     path: "/labelInteract",
      //     name: "LabelInteract",
      //     component: () => import('@/views/InteractGame/labelInteract'),
      //     meta: {
      //       title: "标注互动"
      //     }
      //   },
      //   // {
      //   //   path: "/judgeInteract",
      //   //   name: "JudgeInteract",
      //   //   component: () => import('@/views/InteractGame/judgeInteract'),
      //   //   meta: {
      //   //     title: "判断互动"
      //   //   }
      //   // },
      //   // {
      //   //   path: "/videoInteract",
      //   //   name: "VideoInteract",
      //   //   component: () => import('@/views/InteractGame/videoInteract'),
      //   //   meta: {
      //   //     title: "视频互动"
      //   //   }
      //   // },
      //   // {
      //   //   path: "/audioInteract",
      //   //   name: "AudioInteract",
      //   //   component: () => import('@/views/InteractGame/audioInteract'),
      //   //   meta: {
      //   //     title: "视频跟读互动"
      //   //   }
      //   // },
      //   {
      //     path: "/litCourseManage",
      //     name: "LitCourseManage",
      //     component: () => import('@/views/LitterClassManage/courseManage'),
      //     meta: {
      //       title: "微课课程管理"
      //     }
      //   },
      //   {
      //     path: "/litHourCouMan",
      //     name: "LitHourCouMan",
      //     component: () => import('@/views/LitterClassManage/hourCouMan'),
      //     meta: {
      //       title: "微课课时管理"
      //     }
      //   },
      //   {
      //     path: "/litCourseCategory",
      //     name: "LitCourseCategory",
      //     component: () => import('@/views/LitterClassManage/courseCategory'),
      //     meta: {
      //       title: "微课课程类型"
      //     }
      //   },
      //   {
      //     path: "/courseHourManage", //动态路由
      //     name: "CourseHourManage",
      //     component: () => import('@/views/CourseManage/couHourManage'),
      //     meta: {
      //       title: "教师端课时管理"
      //     }
      //   },
      //   {
      //     path: "/courseManage", //动态路由
      //     name: "courseManage",
      //     component: () => import('@/views/CourseManage/courseManage'),
      //     meta: {
      //       title: "教师端课程管理"
      //     }
      //   },
      //   {
      //     path: "/courseCategory", //动态路由
      //     name: "courseCategory",
      //     component: () => import('@/views/CourseManage/courseCategory'),
      //     meta: {
      //       title: "教师端课程类型管理"
      //     }
      //   },
      //   {
      //     path: "/teacherManage/userManage", //动态路由
      //     component: () => import('@/views/UserManage/teacherManage/userManage'),
      //     meta: {
      //       title: "老师人员管理"
      //     },
      //   },
      //   {
      //     path: "/teacherManage/orderManage", //动态路由
      //     component: () => import('@/views/UserManage/teacherManage/orderManage'),
      //     meta: {
      //       title: "老师订单统计"
      //     },
      //   },
      //   {
      //     path: "/teacherManage/codeManage", //动态路由
      //     component: () => import('@/views/UserManage/teacherManage/codeManage'),
      //     meta: {
      //       title: "老师活码管理"
      //     },
      //   },
      //   {
      //     path: "/teacherManage/questionManage", //动态路由
      //     component: () => import('@/views/UserManage/teacherManage/questionManage'),
      //     meta: {
      //       title: "老师问卷调查"
      //     },
      //   },
      //   {
      //     path: "/studentManage", //动态路由
      //     name: "studentManage",
      //     component: () => import('@/views/UserManage/studentManage'),
      //     meta: {
      //       title: "学生管理"
      //     }
      //   },
      //   {
      //     path: "/studentApp", //动态路由
      //     name: "studentApp",
      //     component: () => import('@/views/versionsManage/studentApp'),
      //     meta: {
      //       title: "学生端管理"
      //     }
      //   },
      //   {
      //     path: "/teacherApp", //动态路由
      //     name: "teacherApp",
      //     component: () => import('@/views/versionsManage/teacherApp'),
      //     meta: {
      //       title: "教师端管理"
      //     }
      //   },
      //   {
      //     path: "/pageAdvertising", //动态路由
      //     name: "pageAdvertising",
      //     component: () => import('@/views/ActivityManage/pageAdvertisingManage'),
      //     meta: {
      //       title: "通栏广告位"
      //     }
      //   },
      //   {
      //     path: "/sliderAdvertising", //动态路由
      //     name: "sliderAdvertising",
      //     component: () => import('@/views/ActivityManage/sliderShowManage'),
      //     meta: {
      //       title: "轮播广告位"
      //     }
      //   },
      //   {
      //     path: "/writeManage", //动态路由
      //     name: "writeManage",
      //     component: () => import('@/views/writeManage'),
      //     meta: {
      //       title: "创作管理"
      //     }
      //   },
      // ]
 *
 *
 *
 */
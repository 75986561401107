/** 权限管理 - 菜单规则 */
import $axios from "../common/http";

/**   @ auth/rule/index  获取菜单列表
 * 
 */
export function getMenuList(data) {
    let res = $axios.get(`S9vC7rYOo4.php/auth/rule/index`);
    return res;
}
/**   @ auth/rule/menu  获取父级菜单列表
 * 
 */
export function getParentMenuList(data) {
    let res = $axios.get(`S9vC7rYOo4.php/auth/rule/menu`);
    return res;
}

/**   @ auth/rule/add  获取菜单列表
 *  type   'menu' or 'file'
    pid   父类id
    name  规则
    title   规则显示名称
    icon 图标
    condition 条件 可以为空
    remark  备注
    ismenu 是否为菜单 0 no 1 yes
    weigh 权重
    status 状态 'normal'  'hidden'
 */
export function addMenuItem(data) {
    let res = $axios.post(`S9vC7rYOo4.php/auth/rule/add`, data);
    return res;
}
/**   @ auth/rule/del  删除菜单
 */
export function delMenuItem(data) {
    let res = $axios.post(`S9vC7rYOo4.php/auth/rule/del?ids=${data}`);
    return res;
}
/**   @ auth/rule/edit  获取菜单
 */
export function getMenuItem(data) {
    let res = $axios.get(`S9vC7rYOo4.php/auth/rule/edit?id=${data}`);
    return res;
}
/**   @ auth/rule/edit  修改菜单
 */
export function editMenuItem(data) {
    let res = $axios.post(`S9vC7rYOo4.php/auth/rule/edit`, data);
    return res;
}
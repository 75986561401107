/** 写作管理 */
import $axios from "../common/http";

/**   @ small/hour/index  获取文章列表
 * 
 */
export function getArticleList(data) {
    let res = $axios.get(`S9vC7rYOo4.php/activity/article/index?page=${data.page}&limit=${data.size}&offset=${data.offset}`);
    return res;
}
/**   @ small/article/edit  获取单个文章列表
 * 
 */
export function getArticleItem(data) {
    let res = $axios.get(`S9vC7rYOo4.php/activity/article/edit?id=${data}`);
    return res;
}
/**   @ small/article/edit  获取单个文章列表
 * 
 */
export function addArticleItem(data) {
    let res = $axios.post(`S9vC7rYOo4.php/activity/article/add`, data);
    return res;
}
/**   @ small/hour/del  删除文章列表
 * 
 */
export function delArticleItem(data) {
    let res = $axios.post(`S9vC7rYOo4.php/activity/article/del?ids=${data}`);
    return res;
}
/**   @ small/hour/edit  修改文章列表
 * 
 */
export function editArticleItem(data) {
    let res = $axios.post(`S9vC7rYOo4.php/activity/article/edit`, data);
    return res;
}
/** 课件管理 */
import $axios from "../../common/http";

/**   @ 获取课件列表
 * 
 */
export function getStuCoursewareList(data) {
    let res = $axios.get(`S9vC7rYOo4.php/client/resource/index?page=1&row=100`);
    return res;
}

/**   @ 添加课件列表
 * 
 */
export function addStuCourseware(data,source) {
    if (source == "studentCourseHour") {
        // 学生端课件
        let res = $axios.post(`S9vC7rYOo4.php/client/resource/add`, data);
        return res;
    }
    let res = $axios.post(`S9vC7rYOo4.php/info/resource/add`, data);
    return res;
}

/**   @ 修改课件列表
 * 
 */
export function editStuCourseware(data) {
    let res = $axios.post(`S9vC7rYOo4.php/client/resource/edit`, data);
    return res;
}
/**   @ 删除课件列表
 * 
 */
export function delStuCourseware(data) {
    let res = $axios.post(`S9vC7rYOo4.php/client/resource/del`, data);
    return res;
}

/**  微课用户管理  - 学生管理 - 我的学生管理 */

import $axios from "../../common/http";
/**   @ small/teacher/my  获取我的学生列表
 * 
 */
export function getMyStuList(data) {
    let res = $axios.get(`S9vC7rYOo4.php/small/teacher/my`);
    return res;
}
/**   调课   ： 只能系统课才可以调课 
 * small/student/adjust  post   
id  学生id
cid  之前得周期id    （开课周期id， 例如l1得开课周期id）
tid  目标id    （开课周期id，例如l2得开课周期id）
 */
export function mixCourse(data) {
    let res = $axios.post(`S9vC7rYOo4.php/small/student/adjust`, data);
    return res;
}
/**   @ small/small/system  获取系统课列表
 *          
 */
export function getSystemCourseList() {
    let res = $axios.get(`S9vC7rYOo4.php/small/small/system`);
    return res;
}
/**   @ small/begin/small  根据课程id获取开课周期列表
 *          
 */
export function getStartCourseList(data) {
    let res = $axios.get(`S9vC7rYOo4.php/small/begin/small?id=${data}`);
    return res;
}
/**   @small/student/info  获取学生购买的系统课信息
 *          
 */
export function getBuyCourseInfo(data) {
    let res = $axios.get(`S9vC7rYOo4.php/small/student/info?id=${data}`);
    return res;
}
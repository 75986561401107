/** 版本管理 */
import $axios from '../common/http';
/*** 添加版本
 *  @params ：oldversion: ""   newversion: ""   packagesize: ""     content: ""   downloadurl: ""   
 *  @params ： enforce:  1, //0-no 1-yes   weigh: ""  status: "normal", //hidden弃用此版本  normal正常
 */
export function addVersion(data) {
    let res = $axios.post(`S9vC7rYOo4.php/version/add`, data);
    return res;
}
/*** 获取单个版本信息
 *  @params ： 
 */
export function getVersionItem(data) {
    let res = $axios.get(`S9vC7rYOo4.php/version/edit?id=${data}`);
    return res;
}
/*** 修改版本信息
 *  @params ： 
 */
export function editVersionItem(data) {
    let res = $axios.post(`S9vC7rYOo4.php/version/edit`, data);
    return res;
}
/*** 查找版本信息列表
 *  @params ： 
 */
export function getVersionList(data) {
    let res = $axios.get(`S9vC7rYOo4.php/version/index`);
    return res;
}
/*** 删除版本信息
 *  @params ： 
 */
export function delVersionItem(data) {
    let res = $axios.post(`S9vC7rYOo4.php/version/del?ids=${data}`);
    return res;
}
// vue-cli @3.11.0
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
/**饿了吗ui调用 */
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)


import plugins from './plugins' // plugins
Vue.use(plugins)


// if (process.env.NODE_ENV == 'development') { // 开发环境！
//   // 给JS里面使用！
//   Vue.prototype.$host = "https://mi.cqtd.games"   // http://139.186.155.167
// }
// if (process.env.NODE_ENV == 'production') { // 生产环境！
//   Vue.prototype.$host = "https://m.kexuemihe.com"  //http://39.98.43.82
// }
// /** axios自动配置其默认接口地址 */
// import $axios from '@/common/http';
// $axios.defaults.baseURL = "https://m.kexuemihe.com";   // http://139.186.155.167
// $axios.defaults.baseURL = Vue.prototype.$host;
Vue.prototype.$host = process.env.VUE_APP_BASE_API;
Vue.prototype.$axios = axios

//  引入 操作cookies插件
import VueCookies from 'vue-cookies'
Vue.use(VueCookies);

// 引入 vue-cryptojs  加密插件 在需要加密的页面单独引入
// import VueCryptojs from 'vue-cryptojs'
// Vue.use(VueCryptojs);
Vue.$cookies.config('3d'); // 设置cookies的有效时间7天  cookies的全局配置  设置数字则会以秒为单位

/** 引入iconfont字体文件 */
import "@/assets/font/iconfont.css"; //加载字体文件
import "@/assets/css/base.css"

Vue.filter('timestampToTime', function (timestamp) {
  var strDate;
  strDate = new Date(timestamp * 1000).toLocaleString();
  return strDate;
})

import UploadLoading from "./components/upload_loading.vue"

Vue.component("UploadLoading", UploadLoading)

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// "dependencies": {
//   "animate.css": "^4.1.1",
//   "babel-plugin-component": "^1.1.1",
//   "babel-plugin-transform-remove-console": "^6.9.4",
//   "core-js": "^3.6.5",
//   "jquery": "^3.5.1",
//   "js-cookie": "^2.2.1",
//   "vue": "^2.6.11",
//   "vue-router": "^3.2.0",
//   "vuex": "^3.4.0"
// },
// "devDependencies": {
//   "@vue/cli-plugin-babel": "^4.5.0",
//   "@vue/cli-service": "^4.5.0",
//   "axios": "^0.21.1",
//   "compression-webpack-plugin": "^7.1.2",
//   "element-ui": "^2.15.0",
//   "less": "^3.0.4",
//   "less-loader": "^5.0.0",
//   "screenfull": "^5.1.0",
//   "sortablejs": "^1.13.0",
//   "uglifyjs-webpack-plugin": "^2.2.0",
//   "vue-template-compiler": "^2.6.11"
// },
<template>
  <div v-show="show" id="upload_loading">上传中... &nbsp;&nbsp;&nbsp;{{ uploadProgress }}%</div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    uploadProgress: 0,
  },
};
</script>

<style scoped>
#upload_loading {
  position: fixed;
  z-index: 10006;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 22px;
}
</style>
export default {
  namespaced: true,
  state: {
    revocationArr: [], // 撤销数据
    curComStyle: null, // 当前组件的style属性
    entrance: 'hour', // model 模块页面 hour 课时页面
    // 页面配置
    pageOptions: {
      add: true,
      edit: true,
      del: true,
      header:{
        disabled: false,
        stepVideo: true,
        process: true,
        material: true,
        exploration: true
      }
    },
    sizeConfig: [{
      icon: "iconjiacu",
      tip: "加粗",
      styleName: "fontWeight",
    }, {
      icon: "iconqingxie",
      tip: "倾斜",
      styleName: "fontStyle",
    }, {
      icon: "iconxiahuaxian",
      tip: "下划线",
      styleName: "textDecoration",
    }, {
      icon: "iconshanchuxian",
      tip: "中划线",
      styleName: "textDecoration",
    }],
    resource_ids: [], // ppt的课时数组信息
    saveStatus: "2",  //保存的状态  0:默认状态  1:有修改 还没进行保存(设置定时器,防抖)   2:保存中 ing / 保存 ed
    ppt: {
      client_hour_id: "", // 课时id
      title: "", // 此课件名称
      type: 0, // 此课件类型
      curShowPage: 0, // 加载ppt成功后，展示的页面下标
    },
    pages: [
      {
        id: "0", // 页面id
        type: "page", // 页面类型
        title: "----", // 页面标题
        isScreen: false, //   false - 备课模式   true - 上课模式
        createTime: "", // 创建时间
        updateTime: "", // 上次更新时间
        ratio: "16:9", // 页面宽高比
        background: {
          // backgroundColor: "#000",
          // backgroundPosition: "center",
          // backgroundSize: "100% 100%",
          // backgroundRepeat: "no-repeat",
          // backgroundOrigin: "",
          // backgroundClip: "",
          // backgroundAttachment: "",
          // backgroundImage: "url('https://i.kelexuexi.com/materials/any/20190806/5c26a4ec2e7f05abdc588f7d41da70f0d072d2061493ca2d47085f2ba240d2bd/lfit-200')"
        },
        curSelectComponent: null, //当前被选中的元素下标    为空则是当前无选择的元素
        /** 
         *  文本： 
         *      height:auto;其高度通过line-height来设置    
         *      子元素span默认white-space: pre-wrap; word-wrap: break-word;可换行
         *      给文本外的p标签contenteditable="true"(使文本直接可编辑)  spellcheck="false"(不开启检测文本内容)
         *      文本样式: 如加粗 倾斜等属性，有的话则在style里有这个值，没有的话则不会有fontWeight... 
         *  图片：
         *      style是给.coms的，其子级的图片标签默认width:100%;height:100%;
         *      
         */
        components: [ // 使用有序数据结构  方便之后   拓展图层功能
          // {
          //     id: "0",
          //     type: "text", // text / image / video / audio / line 
          //     title: "", //  组件名称  --  用来分辨组件
          //     lockCoverage: false, // 图层是否被锁定
          //     style: {
          //         width: "500px",
          //         // color: "#000",
          //         fontSize: "24px",
          //         textAlign: "left",
          //         position: "absolute",
          //         left: "100px",
          //         top: "110px",
          //         fontWeight: 600,
          //         // fontStyle: 'italic',
          //         textDecoration: 'underline line-through',
          //         opacity: "60%",
          //     },
          //     event: {
          //         // name:"clickPlay",    // 点击播放事件
          //         // url:"",  // 点击播放事件 弹窗的素材url
          //         // type:"",    // image  video   audio
          //     },
          //     content: `测试数据`
          // },

        ]
      }
    ],
    /**  curSelectType作用：
        *  1. 控制右侧栏显隐
        */
    curSelectType: null,
    showRightEventDislog: false, // 0: 不显示    1:显示并且弹出所有操作     2:显示表示图层相关操作变为可选
    copyCom: null,
    curSelectComponent: null,
    delResourceArr: [],
  },
  getters: {
    entrance: state =>state.entrance,
    pageOptions: state => state.pageOptions,
    revocationArr: state => state.revocationArr,
    curComStyle: state => state.curComStyle,
    sizeConfig: state => state.sizeConfig,
    resource_ids: state => state.resource_ids,
    ppt: state => state.ppt,
    pages: state => state.pages,
    coms: state => state.pages[state.ppt.curShowPage].components,
    curSelectComponent: state => state.pages[state.ppt.curShowPage].curSelectComponent,
    curSelectType: state => state.curSelectType,
    showRightEventDislog: state => state.showRightEventDislog,
    copyCom: state => state.copyCom,
    saveStatus: state => state.saveStatus,
    delResourceArr: state => state.delResourceArr,
  },
  mutations: {
    SET_Entrance(state, str) {
      state.entrance = str
    },
    SET_PageOptions(state, options) {
      state.pageOptions = options
    },
    SET_revocationArr(state, data) {
      state.revocationArr = data
    },
    SET_curComStyle(state, data) {
      state.curComStyle = data
    },
    /** 保存状态   Boolean */
    SET_saveStatus(state, data) {
      state.saveStatus = data;
    },
    /** 进入ppt编辑页面时，将resource_ids保存，方便之后修改ppt信息 */
    SET_resource_ids(state, data) {
      state.resource_ids = data;
    },
    /** 复制的组件内容 */
    SET_copyCom(state, data) {
      state.copyCom = data;
    },
    /** 修改 控制右击弹窗显隐 */
    SET_showRightEventDislog(state, data) {
      state.showRightEventDislog = data;
    },
    /** 修改ppt */
    SET_ppt(state, data) {
      state.ppt = data;
    },
    SET_concatPPT(state, data) {
      state.ppt = { ...state.ppt, ...data };
    },
    /** 修改pages */
    SET_pages(state, data) {
      state.pages = data;
    },
    /** 修改curSelectType */
    SET_curSelectType(state, data) {
      state.curSelectType = data
    },
    /** 修改curSelectComponent */
    SET_curSelectComponent(state, data) {
      state.pages[state.ppt.curShowPage].curSelectComponent = state.curSelectComponent = data
    },
    /** 修改curShowPage */
    SET_curPage(state, data) {
      state.ppt.curShowPage = data;
    },
    /** 修改components */
    SET_coms(state, data) {
      console.log(data);
      state.pages[state.ppt.curShowPage].components = data
    },
    /** 修改page的background */
    SET_background(state, data) {
      state.pages[state.ppt.curShowPage].background = data
    },
    /** 修改pages的background */
    SET_pagesBackground(state, data) {
      /** data对象是复杂类型数据：所有的page的background都是data的话，会造成之后修改一个背景所有页面背景全部变化
       *     解决方法: 将data结构赋值给background,这样每个page的background在堆中的地址就不是相同的了
       */
      state.pages.forEach((item) => {
        item.background = {
          ...data
        }
      });
    },
    /** 修改pages的删除操作 */
    SET_delResourceArr(state, data) {
      state.delResourceArr = data
    }
  },
  actions: {}
}
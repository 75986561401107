import Vue from 'vue'
import Vuex from 'vuex'

// import vueRouter from "vue-router"
import judgeInteract from "./modules/judgeInteract"
import couHourManage from "./modules/couHourManage"
import courseManage from "./modules/courseManage"
import courseCategory from "./modules/courseCategory"
import litterCouHourManage from "./modules/litterCouHourManage"
import litterCourseManage from "./modules/litterCourseManage"
import litterCourseCategory from "./modules/litterCourseCategory"
import versionsManage from "./modules/versionsManage"
import writeManage from "./modules/writeManage"
import activityManage from "./modules/activityManage"
import menuManage from "./modules/menuManage"
import userGroupManage from "./modules/userGroupManage"
import adminManage from "./modules/adminManage"
import transactionManage from "./modules/transactionManage"
import commodityManage from "./modules/commodityManage"
import clientUserManage from "./modules/clientUserManage"
import adminLog from "./modules/adminLog"
import personalData from "./modules/personalData"
import schoolManage from "./modules/schoolManage"
import teacherUser from "./modules/teacherUser"
import checkOrganManage from "./modules/checkOrganManage"
import teacherNoticeManage from "./modules/teacherNoticeManage"
import adminNoticeManage from "./modules/adminNoticeManage"
import litterTeacherManage from "./modules/userManage/litterUserManage/litterTeacherManage"
import myStudentManage from "./modules/userManage/litterUserManage/myStudentManage"
import stuUserManage from "./modules/userManage/litterUserManage/stuUserManage"
import imageMediaManage from "./modules/imageMediaManage"
import videoMediaManage from "./modules/videoMediaManage"
import orgCourseHourManage from "./modules/orgCourseHourManage"
import orgCourseManage from "./modules/orgCourseManage"
import saleUserManage from "./modules/saleUserManage"
import presellManage from "./modules/presellManage"
//------------------------ ppt编辑器 -------------------------------
import visualEditer from './modules/visualEditer/visualEditer'
import editOperation from './modules/visualEditer/editOperation'
import editPages from './modules/visualEditer/editPages'
import editPreview from './modules/visualEditer/editPreview'
import editerHeader from './modules/visualEditer/editerHeader'
//------------------------ 学生端课程 -------------------------------
import studentCourseManage from "./modules/studentManage/studentCourseManage"
import studentCourseCategory from "./modules/studentManage/studentCourseCategory"
import studentCourseHourManage from "./modules/studentManage/studentCourseHourManage"
import studentVideoManage from "./modules/studentManage/studentVideoManage"
import studentCoursewareManage from "./modules/studentManage/studentCoursewareManage"
import tagsManage from "./modules/studentManage/tagsManage"
import customerServiceManage from "./modules/studentManage/customerServiceManage"
//------------------------ 小程序问答游戏 -------------------------------
import activeGame from "./modules/mianGame/activeGame"
import checkpointGame from "./modules/mianGame/checkpointGame"
/* ============================种植游戏 种子档案================================== */
import planTing from "./modules/planting/planting"
import PPTEditer from './modules/PPTEditer'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    aliyunUrl: "http://video.mxiaotu.com/",
    truePlayAud: "http://video.mxiaotu.com/sv/40bbf420-1774681e06c/40bbf420-1774681e06c.mp3",
    jwt: "",
    permissionArr: [],
  },
  getters: {
    aliyunUrl: state => state.aliyunUrl,
    truePlayAud: state => state.truePlayAud,
    jwt: state => state.jwt,
    permissionArr: state => state.permissionArr
  },
  mutations: { //只能放同步操作
    SET_jwt(
      state,
      data
    ) {
      state.jwt = data;
    },
    SET_permissionArr(
      state,
      data
    ) {
      state.permissionArr = data;
    },
  },
  actions: { //可以放异步操作 

  },
  modules: {
    //  ppt可视化编辑
    visualEditer,
    PPTEditer, // PPT可视化编辑器
    editOperation,
    editPages,
    editPreview,
    editerHeader,
    // 客户管理
    saleUserManage,  // 销售管理
    clientUserManage, // 客户管理  --- 展会客户管理
    // 精品课程
    couHourManage,
    courseManage,
    courseCategory,
    orgCourseHourManage,　　　//精品课程 - - 课时管理
    orgCourseManage,　　　//精品课程 - - 课程管理
    // 微课管理
    litterCourseCategory,
    litterCourseManage,
    litterCouHourManage,
    litterTeacherManage, //微课用户 -老师管理 - 老师人员管理
    // ---------------------------------------------
    schoolManage, // 教师端用户管理 - 校区管理
    teacherUser, //教师端用户管理 - 人员管理
    checkOrganManage, //教学端用户管理 - - 机构认证
    teacherNoticeManage, //教师端用户管理 - - 消息管理
    videoMediaManage,   //　教师端用户管理 - - 视频库管理
    imageMediaManage,　　　//　教师端用户管理 - - 图片库管理
    // ---------------------------------------------
    userGroupManage, //角色组
    adminLog, // 权限管理 - 管理员日志
    personalData, // 常规管理 - 个人资料
    adminNoticeManage, //常规操作 - - 后台消息管理
    menuManage, //菜单规则
    adminManage, //管理员管理
    // ---------------------------------------------
    stuUserManage, //微课用户 - 学生管理 - 人员管理
    myStudentManage, //微课用户 - 学生管理 - 我的学生管理
    // ---------------------------------------------
    judgeInteract,
    versionsManage,  // 版本管理
    writeManage, // 创作管理
    activityManage, //活动管理
    // 订单管理
    transactionManage, // 订单管理 --- 交易管理
    presellManage, // 订单管理 --- 交易管理
    commodityManage, // 订单管理  --- 商品管理
    /** 学生端课程 */
    studentCourseManage,
    studentCourseCategory,
    studentCourseHourManage,
    studentVideoManage,
    studentCoursewareManage,
    tagsManage,
    customerServiceManage,
    /*  小程序问答游戏 */
    activeGame,
    checkpointGame,
    /* 种植游戏 种子档案 */
    planTing
  }
})
/** 权限管理 - 管理员管理 */
import $axios from '../common/http';
/**   @ auth/admin/index  获取管理员列表
 * 
 */
export function getAdminList(data) {
    let res = $axios.get(`S9vC7rYOo4.php/auth/admin/index`);
    return res;
}
/**   @ auth/admin/add  获取管理员列表
username 用户名    nickname 昵    wxname 微信名称    password 密码    avatar 头像    org_id 机构id     
email 邮箱       content 描述       status 状态  normal or checked or hidden
 */
export function addAdminItem(data) {
    let res = $axios.post(`S9vC7rYOo4.php/auth/admin/add`, data);
    return res;
}
/**   @ auth/admin/del  删除管理员
 */
export function delAdminItem(data) {
    let res = $axios.post(`S9vC7rYOo4.php/auth/admin/del?ids=${data}`);
    return res;
}
/**   @ auth/admin/edit  获取管理员
 */
export function getAdminItem(data) {
    let res = $axios.get(`S9vC7rYOo4.php/auth/admin/edit?id=${data}`);
    return res;
}
/**   @ auth/admin/edit  修改管理员
 */
export function editAdminItem(data) {
    let res = $axios.post(`S9vC7rYOo4.php/auth/admin/edit`, data);
    return res;
}

/**   @ auth/admin/info  有group还有org得信息。
 * 
 */
export function getGroupList() {
    let res = $axios.get(`S9vC7rYOo4.php/auth/admin/info`);
    return res;
}
/** 教师用户管理  - 图片 / 视频管理 */
import $axios from "../common/http";

/**   @ info/material/index 获取素材列表
 *         type = video    type != video
 * 
 */
export function getList(data) {
    let res;
    if (data.filename) {
        res = $axios.get(`S9vC7rYOo4.php/info/material/backend?page=${data.page}&limit=${data.size}&filter={"type":"video","filename":"${data.filename}"}&op={"type":"${data.type}","filename":"LIKE"}`);
        return res;
    }
    res = $axios.get(`S9vC7rYOo4.php/info/material/backend?page=${data.page}&limit=${data.size}&filter={"type":"video"}&op={"type":"${data.type}"}`);
    return res;
}
/**   @ info/material/upload_image  info/material/upload_video  上传素材 
 */
export function uploadMedia(isType, data) {
    let res;
    if (isType == 'image') {
        res = $axios.post(`S9vC7rYOo4.php/info/material/upload_image`, data);
    } else {
        res = $axios.post(`S9vC7rYOo4.php/info/material/upload_video`, data);
    }

    return res;
}
/**   @ info/material/del 删除素材 
 */
export function delItem(data) {
    let res = $axios.post(`S9vC7rYOo4.php/info/material/del`, data);
    return res;
}
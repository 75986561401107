//引入接口
import { getQuesitonItem } from "../../../request/activeGame/activeGame"
export default {
    namespaced: true,
    state: {
        list: [], // 题目列表  固定十题 无需分页
    },
    getters: {
        list: state => state.list,
    },
    mutations: {
        SET_LIST(state, payload) {
            state.list = payload;
        }
    },
    actions: {
        async getQuesitonItem({ commit }, payload) {
            let res = await getQuesitonItem(payload)
            commit('SET_LIST', res.rows)
            console.log(res);
        }
    }
}
/** 课时管理 */
import $axios from "../../common/http";
/*** 查找课时
 *  @params ： 
 */
export function searchStuCouHour(data) {
    let res = $axios.get(`S9vC7rYOo4.php/client/hour/index?filter=${JSON.stringify(data)}&op={"name":"LIKE"}`);
    return res;
}
/**   @ 获取课时列表
 * 
 */
export function getStuCourseHourList(data) {
    let res = $axios.get(`S9vC7rYOo4.php/client/hour/index?page=${data.page}&limit=${data.size}`);
    return res;
}
export function getStuCourseHourListwo(data) {
    let res = $axios.get(`S9vC7rYOo4.php/client/hour/index?page=${data.page}&limit=${data.size}&is_admin=1&name=${data.name}&create_label_id=${data.create_label_id}`);
    return res;
}
/**   @ 添加课时列表
 * 
 */
export function addStuCourseHour(data) {
    let res = $axios.post(`S9vC7rYOo4.php/client/hour/add`, data);
    return res;
}

/**   @ 修改课时列表
 * 
 */
export function getStuCourseHour(data) {
    let res = $axios.get(`S9vC7rYOo4.php/client/hour/edit?id=${data}`);
    return res;
}
/**   @ 修改课时列表
 * 
 */
export function editStuCourseHour(data) {
    let res = $axios.post(`S9vC7rYOo4.php/client/hour/edit`, data);
    return res;
}
/**   @ 删除课时列表
 * 
 */
export function delStuCourseHour(data) {
    let res = $axios.post(`S9vC7rYOo4.php/client/hour/del`, data);
    return res;
}
/**   @ 生成二维码
 * 
 */
export function createQrCode(data) {
    let res = $axios.post(`S9vC7rYOo4.php/client/hour/qrcode`, data);
    return res;
}

/**   @ 通过课时id获取上面课程列表
 * 
 */
export function getCourseByHourId(data) {
    let res = $axios.post(`S9vC7rYOo4.php/client/hour/getCourseByHourId`, data);
    return res;
}


/** 权限管理 - 角色规则 */
import $axios from "../common/http";

/**   @ auth/group/index  获取角色组列表
 * 
 */
export function getUserGroupList(data) {
    let res = $axios.get(`S9vC7rYOo4.php/auth/group/index`);
    return res;
}
/**   @ auth/group/select  获取角色组信息
 * 
 */
export function getParentUserGroupList(data) {
    let res = $axios.get(`S9vC7rYOo4.php/auth/group/select`);
    return res;
}
/**@ auth/group/add  添加角色列表
 * pid                  父id
name              名称
rlues               规则id   字符串（id,id,id,id）如果为超级管理员组别   请用   * 
status    'normal' or 'hidden'
 */
export function addUserGroupItem(data) {
    let res = $axios.post(`S9vC7rYOo4.php/auth/group/add`, data);
    return res;
}
/**   @ auth/group/del  删除角色
 */
export function delUserGroupItem(data) {
    let res = $axios.post(`S9vC7rYOo4.php/auth/group/del?ids=${data}`);
    return res;
}
/**   @ auth/group/edit  获取角色
 */
export function getUserGroupItem(data) {
    let res = $axios.get(`S9vC7rYOo4.php/auth/group/edit?id=${data}`);
    return res;
}
/**   @ auth/group/edit  修改角色
 */
export function editUserGroupItem(data) {
    let res = $axios.post(`S9vC7rYOo4.php/auth/group/edit`, data);
    return res;
}
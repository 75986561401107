/** 客户管理  -- 展会客户管理 */
import $axios from "../common/http";

/**   @ activity/gather/index  获取展会扫码的用户列表
 * 
 */
export function getClientUserList(data) {
    let res = $axios.get(`S9vC7rYOo4.php/activity/gather/index?page=1&row=10`);
    return res;
}
/**   @ activity/gather/show  查看用户信息
 * 
 */
export function getUserItemOrder(data) {
    let res = $axios.get(`S9vC7rYOo4.php/activity/gather/show?id=${data}`);
    return res;
}
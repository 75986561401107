import $axios from "../../common/http";

/* 
    /client/botany/index add edit del
*/

/**
 *  上传视频/图片
 */
export function uploadMedia(data) {
    let res;
    res = $axios.post(`platform/upload/upload_image`, data);
    return res;
}
/**
 *  种子档案列表
 */
export function getBotanyItem(data) {
    let res;
    res = $axios.get(`S9vC7rYOo4.php/client/botany/index`);
    return res;
}
/**
 *  添加种子档案
 */
export function addBotanyItem(data) {
    let res;
    res = $axios.post(`S9vC7rYOo4.php/client/botany/add`, data);
    return res;
}
/**
 *  删除种子档案
 */
export function delBotanyItem(data) {
    let res;
    res = $axios.post(`S9vC7rYOo4.php/client/botany/del`, data);
    return res;
}
/**
 *  edit   post修改种子档案  get 获取一条
 */
export function editBotanyItem(type, data) {
    console.log(type, data);
    let res;
    if (type === 0) {
        res = $axios.get(`S9vC7rYOo4.php/client/botany/edit?id=${data}`);
    } else if (type === 1) {
        res = $axios.post(`S9vC7rYOo4.php/client/botany/edit`, data);
    }
    return res;
}
export default {
    namespaced: true,
    state: {
        curComStyle: null, // 当前组件的style属性
        sizeConfig: [{
            icon: "iconjiacu",
            tip: "加粗",
            styleName: "fontWeight",
        }, {
            icon: "iconqingxie",
            tip: "倾斜",
            styleName: "fontStyle",
        }, {
            icon: "iconxiahuaxian",
            tip: "下划线",
            styleName: "textDecoration",
        }, {
            icon: "iconshanchuxian",
            tip: "中划线",
            styleName: "textDecoration",
        }, ]
    },
    getters: {
        curComStyle: state => state.curComStyle,
        sizeConfig: state => state.sizeConfig,
    },
    mutations: {
        SET_curComStyle(state, data) {
            // console.log(data);
            state.curComStyle = data
        },
    },
    actions: {}
}
/**   微课课时管理
 *   
 */
import $axios from "../common/http";
/**   @ index/login  获取菜单列表
 *  @param:username password
 */
export function indexLogin(data) {
    let res = $axios.post(`S9vC7rYOo4.php/index/login`, data);
    return res;
}
/**   @ index/index  获取用户权限列表  
 */
export function getPermissionList() {
    let res = $axios({
        method: "POST",
        url: `S9vC7rYOo4.php/index/index`,
        params: {
            action: "refreshmenu",
        }
    })
    return res;
}
/**   @ /index/logout  注销登录：用户jwt失效
 */
export function exit() {
    let res = $axios({
        method: "post",
        url: `S9vC7rYOo4.php/index/logout`,
    })
    return res;
}
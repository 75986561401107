import $axios from "../../common/http";
/* *
 *  上传视频/图片
 */
export function uploadMedia(type, data) {
    let res;
    if (type == 'video' || type == 'audio') {
        res = $axios.post(`platform/upload/upload_video`, data);
    }
    if (type == 'image') {
        res = $axios.post(`platform/upload/upload_image`, data);
    }
    return res;
}

/* *  
 *  @ client/level/index   搜索 （全部）
 */
export function fifterLeveItem(data) {
    let a = encodeURIComponent(JSON.stringify({
        title: "like" // op 条件 
    }))
    let b = encodeURIComponent(JSON.stringify({
        title: data.name
    }))
    let res = $axios.get(`S9vC7rYOo4.php/client/question/index?op=${a}&filter=${b}`);
    return res;
}

/* *
*   @ client/question/export  题库添加
*/

export function addExportlItem(data) {
    let res = $axios.post(`S9vC7rYOo4.php/client/question/export`, data, {
        timeout: 0
    });
    return res;
}

/* *   
* @  client/question/index    题库列表
*/
export function getQuesitonItem(data) {
    let res = $axios.get(`S9vC7rYOo4.php/client/question/index?offset=${data.offset}&limit=10`);
    return res;
}

/* *   
 * @ client/question/edit    获取题库单条数据
 */
export function editQuesitonItem(data, type) {
    let res = null;
    if (type) {
        res = $axios.get(`S9vC7rYOo4.php/client/question/edit?id=${data}`);
    } else {
        res = $axios.post(`S9vC7rYOo4.php/client/question/edit`, data);
    }

    return res;
}

/* *  
 *  @ client/question/del    题库删除一条
 */
export function delQuesitonItem(data) {
    let res = $axios.post(`S9vC7rYOo4.php/client/question/del`, data);
    return res;
}

/* *  
 *  @ 百度tts短文本语音合成接口 （获取token）
 */
export function getBaiDuTTS(data) {
    let res = $axios.get(`https://aip.baidubce.com/oauth/2.0/token?grant_type=${data.grant_type}&client_id=${data.client_id}&client_secret=${data.client_secret}`);
    return res;
}

/* *   
 * @ 课时ID查询
 */
export function searchStuCouHour(data) {
    let res = $axios.get(`S9vC7rYOo4.php/client/hour/index?filter=${JSON.stringify(data)}&op={"name":"LIKE"}`);
    return res;
}
export function addLevelItem(data) {
    
}
export function addQuesitonItem(data) {
    
}
export function delLeveItemList(data) {
    
}
export function editLeveItemList(data) {
    
}
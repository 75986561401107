import {
    getStuCourseList
} from "../../../request/studentManage/studentCourseManage";
export default {
    namespaced: true,
    state: {
        list: [], // 课时列表信息
        page: 1, // 页码数  --- 第几页
        size: 10, // 每页数量
        total: 0, //总条数
        isDragOrg: false, //是否可以拖拽机构列表
    },
    getters: {
        list: state => state.list,
        page: state => state.page,
        size: state => state.size,
        total: state => state.total,
        isDragOrg: state => state.isDragOrg,
    },
    mutations: {

        SET_ISDRAGORG(state, data) {
            state.isDragOrg = data
        },

        SET_LIST(state, data) {
            state.list = data
        },
        SET_PAGE(state, data) {
            state.page = data
        },

        SET_SIZE(state, data) {
             console.log(8888)
            state.size = data
        },
        SET_TOTAL(state, data) {
            state.total = data
        },
    },
    actions: {
        async get_list({
            commit,
            state
        },filter) {
            
            let data = {
                page: state.page,
                size: state.size,
            }
            let res = await getStuCourseList(data,filter);
            // console.log(res);
            commit('SET_LIST', res.rows);
            commit('SET_TOTAL', res.total);
        },
        set_page({
            commit,
            dispatch
        }, data) {
            commit('SET_PAGE', data)
            // dispatch('get_list')
        },
        set_size({
            commit,
            dispatch
        }, data) {
           
            commit('SET_SIZE', data)
            // dispatch('get_list')
        },
    }
}
/** 教师端用户管理 - 校区管理 */
import $axios from "../common/http";

/**   分配课程
 * id: 机构 id   cids: 课程数组
 */
export function allotCourse(data) {
    let res = $axios.post(`S9vC7rYOo4.php/info/organization/course`, data);
    return res;
}
/**   分配课程 所需属性图的数据
 * id: 机构 id   cids: 课程数组
 */
export function getTreeData() {
    let res = $axios.get(`S9vC7rYOo4.php/info/course/type`);
    return res;
}
/**   获取机构的课程权限id数组   回写树形图
 *  get 请求  机构id
 */
export function setCheckedTreeNode(data) {
    let res = $axios.get(`S9vC7rYOo4.php/info/organization/course_ids?id=${data}`);
    return res;
}
/**   @ info/organization/index  获取校区列表
 * 
 */
export function getSchoolList(data) {
    let res = $axios.get(`S9vC7rYOo4.php/info/organization/index?page=${data.page}&limit=${data.size}&offset=${data.offset}`);
    return res;
}
/**   @ info/organization/index  查找
 * 
 */
export function searchSchool(data) {
    let res = $axios.get(`S9vC7rYOo4.php/info/organization/index?filter=${JSON.stringify(data)}&op={"name":"LIKE"}`);
    return res;
}
/**   @ info/organization/del  删除校区项
 * 
 */
export function delSchoolItem(data) {
    let res = $axios.post(`S9vC7rYOo4.php/info/organization/del?ids=${data}`);
    return res;
}
/**   @ info/organization/edit  获取校区项
 * 
 */
export function getSchoolItem(data) {
    let res = $axios.get(`S9vC7rYOo4.php/info/organization/edit?id=${data}`);
    return res;
}
/**   @ info/organization/edit  获取校区项
 * 
 */
export function editSchoolItem(data) {
    let res = $axios.post(`S9vC7rYOo4.php/info/organization/edit`, data);
    return res;
}
/**   @ info/organization/add  获取校区项
 * 
 */
export function addSchoolItem(data) {
    let res = $axios.post(`S9vC7rYOo4.php/info/organization/add`, data);
    return res;
}
/**   @ info/organization/teacher  查看校区成员的列表
 * 
 */
export function getSchoolTeacherList(data) {
    let res = $axios.get(`S9vC7rYOo4.php/info/organization/teacher?id=${data}`);
    return res;
}
/**   @ info/organization/apply  查看校区成员的列表
 * 
 */
export function getCheckList(data) {
    let res = $axios.get(`S9vC7rYOo4.php/info/organization/apply?id=${data}`);
    return res;
}
/**   @ info/organization/del_teacher  删除校区成员
 * ids  教师的id  可以是多个
org  机构的id 
 */
export function delSchoolTeacherItem(data) {
    let res = $axios.post(`S9vC7rYOo4.php/info/organization/del_teacher`, data);
    return res;
}
/**   @ info/organization/applyedit    审批加入机构的成员
 * id         status ：状态 0 等待处理 1 通过 2 失败 3 退出 4 清除        remark
 */
export function checkJoin(data) {
    let res = $axios.post(`S9vC7rYOo4.php/info/organization/applyedit`, data);
    return res;
}
/**   @ info/organization/set    为机构绑定教学平台用户
 *  tid 教师id    id 机构id
 */
export function bindAdmin(data) {
    let res = $axios.get(`S9vC7rYOo4.php/info/organization/set?tid=${data.tid}&id=${data.id}`);
    return res;
}
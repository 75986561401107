/**  微课用户管理  - 学生管理 - 人员管理 */

import $axios from "../../common/http";

/**  -------------------------------------------------微课学生用户管理接口 */
/**   @ small/student/index  获取列表
 * 
 */
export function getLitterStudentList(data) {
    console.log(data);
    let res = $axios.get(`S9vC7rYOo4.php/small/student/index?page=${data.page}&limit=${data.size}&offset=${data.offset}`);
    return res;
}
/**   @ small/student/index  查找条件对应信息
 * 
 */
export function getFilterInfo(data) {
    console.log(data);
    let res = $axios.get(`S9vC7rYOo4.php/small/student/index?filter=${JSON.stringify(data)}&op={"mobile":"LIKE"}`);
    return res;
}
/**   @ small/student/add  添加
 * 
 */
export function addLitterStudentItem(data) {
    let res = $axios.post(`S9vC7rYOo4.php/small/student/add`, data);
    return res;
}
/**   @ small/student/del  删除列表
 * 
 */
export function delLitterStudentItem(data) {
    let res = $axios.post(`S9vC7rYOo4.php/small/student/del?ids=${data}`);
    return res;
}
/**   @ small/student/small  获取list页面 分配课程弹窗里的下拉信息  
 * 
 */
export function getListSelectInfo(data) {
    let res = $axios.get(`S9vC7rYOo4.php/small/student/small?id=${data}`);
    return res;
}
/**   @ small/student/edit  获取单个
 * 
 */
export function getLitterStudentItem(data) {
    let res = $axios.get(`S9vC7rYOo4.php/small/student/edit?id=${data}`);
    return res;
}
/**
 * @ small/student/choose
 * @ post 传uid  用户id  sid 微课id
 */
export function chooseCourse(data) {
    let res = $axios.post(`S9vC7rYOo4.php/small/student/choose`, data);
    return res;
}
/**   @ small/student/cycle  分配开课时间
 * 
 */
export function commitAllotCourse(data) {
    let res = $axios.post(`S9vC7rYOo4.php/small/student/cycle`, data);
    return res;
}
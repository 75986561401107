/** 权限管理 - 管理员日志 */
import $axios from "@/common/http";

/**   @ activity/adminlog/index  获取管理员日志列表
 * 
 */
export function getAdminLogList(data) {
    let res = $axios.get(`S9vC7rYOo4.php/auth/adminlog/index?page=${data.page}&limit=${data.size}&offset=${data.offset}`);
    return res;
}
/**   @ activity/adminlog/detail  获取管理员日志列表
 * 
 */
export function getAdminLogDetail(data) {
    let res = $axios.get(`S9vC7rYOo4.php/auth/adminlog/detail?id=${data}`);
    return res;
}
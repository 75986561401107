/** 微课管理 - 微课程管理模块接口 */
import $axios from '../common/http';
// index是列表  add是添加  edit是编辑-传id  del是删除-传ids
/** small/begin/index  获取课程的开课时间字段列表
 *  @param:filter: {"small_begin.small_id":课程id}  op: {small_id:"LIKE"}
 */
export function getStartTimeList(data) {
    let res = $axios.get(`S9vC7rYOo4.php/small/begin/index?filter=${JSON.stringify(data)}&op={small_id:"LIKE"}`);
    return res;
}
/** small/begin/add  给课程添加开课时间项 
 * 
 */
export function addStartTimeItem(data) {
    let res = $axios.post(`S9vC7rYOo4.php/small/begin/add`, data);
    return res;
}
/** small/begin/edit  修改课程开课时间项 
 * 
 */
export function editStartTimeItem(data) {
    let res = $axios.post(`S9vC7rYOo4.php/small/begin/edit`,data);
    return res;
}
/** small/begin/del  删除课程开课时间项 
 * 
 */
export function delStartTimeList(data) {
    let res = $axios.get(`S9vC7rYOo4.php/small/begin/del?id=${data}`);
    return res;
}
/** 获取大课列表和课程类型列表
 * @param ：
 */
export function getBigCourseList() {
    let res = $axios.get(`S9vC7rYOo4.php/small/small/add`);
    return res;
}
//---------------------------------------课程类型--------------------------------------------
/**  获取课程类型列表 
 *  @param :
 */
export function getLitCourseTypeList(data) {
    let res = $axios.get(`S9vC7rYOo4.php/small/type/index?page=${data.page}&limit=${data.size}`);
    return res;
}
/**  获取课程类型信息
 *  @param :
 */
export function getLitCourseType(data) {
    let res = $axios.get(`S9vC7rYOo4.php/small/type/edit?id=${data.id}`);
    return res;
}
/**  修改课程类型信息
 *  @param :
 */
export function modifyLitCourseType(data) {
    let res = $axios.post(`S9vC7rYOo4.php/small/type/edit`, data);
    return res;
}
/**  添加课程类型信息
 *  @param :
 */
export function addLitCourseType(data) {
    let res = $axios.post(`S9vC7rYOo4.php/small/type/add`, data);
    return res;
}
// -----------------------------------------课程-----------------------------------------------------
/**  获取课程列表 
 *  @param :
 */
export function getLitCourseList(data) {
    // let res = $axios.get(`S9vC7rYOo4.php/small/small/index?page=${data.page}&limit=${data.size}&offset=${data.offset}`);
    let res = $axios.get(`S9vC7rYOo4.php/small/small/index`);
    return res;
}
/**  获取课程信息 
 *  @param : id
 */
export function getLitCourse(data) {
    let res = $axios.get(`S9vC7rYOo4.php/small/small/edit?id=${data}`);
    return res;
}
/**  添加课程
 *  @param : hour_ids   name 微课名称		type 类型		pid 父	id	origin 原价		price 活动价格		tag 标签 1=>'3-5岁', 2=>'5-9岁', 3=>'9-12岁'		cover_url 封面		begin 开课日期		begin 描述		    is_url 外部链接 		remark 微课备注           url      
 */
export function addLitCourse(data) {
    console.log(data);
    let res = $axios.post(`S9vC7rYOo4.php/small/small/add`, data);
    return res;
}
/**  修改课程
 *  @param :  hour_ids   name-微课名称		type-类型		pid-父	id	origin 原价		price 活动价格		tag-标签 1=>'3-5岁', 2=>'5-9岁', 3=>'9-12岁'		'cover_url-封面		begin-开课日期		begin-描述		    is_url-外部链接 		remark-微课备注           url      
 */
export function editLitCourse(data) {
    console.log(data);
    let res = $axios.post(`S9vC7rYOo4.php/small/small/edit`, data);
    return res;
}
/**  删除课程
 *  @param : ids
 */
export function delLitCourse(data) {
    let res = $axios.post(`S9vC7rYOo4.php/small/small/del?ids=${data.ids}`);
    return res;
}
// ---------------------------------------------------------------------------------------------
/**  获取微课时列表 
 *  @param :
 */
export function getLitCourseHourList(data) {
    let res = $axios.get(`S9vC7rYOo4.php/small/hour/look?page=1&row=100`);
    return res;
}
/**  获取课时信息 
 *  @param : id
 */
export function getLitCourseHour(data) {
    let res = $axios.get(`S9vC7rYOo4.php/small/hour/edit?id=${data.id}`);
    return res;
}
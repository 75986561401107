import {
  getStuCourseHourList
} from "../../../request/studentManage/studentVideoManage";

export default {
  namespaced: true,
  state: {
      list: [], // 课时列表信息
      page: 1, // 页码数  --- 第几页
      size: 10, // 每页数量
      total: 0, //总条数
      courseComponentPage: 1, // 页码数  --- 第几页   为了课程管理里需要获取课时列表渲染
  },
  getters: {
      list: state => state.list,
      page: state => state.page,
      size: state => state.size,
      total: state => state.total,
      courseComponentPage: state => state.courseComponentPage,
  },
  mutations: {
      SET_LIST(state, data) {
          state.list = data
      },
      SET_PAGE(state, data) {
          state.page = data
      },

      SET_SIZE(state, data) {
          state.size = data
      },
      SET_TOTAL(state, data) {
          state.total = data
      },
      SET_courseComponentPage(state, data) {
          state.courseComponentPage = data;
      },
  },
  actions: {
      async get_list({
          commit,
          state
      }) {
          let data = {
              page: state.page,
              size: state.size,
          }
          let res = await getStuCourseHourList(data);
          // console.log(res);
          commit('SET_LIST', res.rows);
          commit('SET_TOTAL', res.total);
      },
      async get_courseComponentList({
          commit,
          state
      }) {
          let data = null;
          if (state.courseComponentPage == 1) {
              data = {
                  page: state.courseComponentPage,
                  size: 10,
                  offset: 0
              }
          } else {
              data = {
                  page: state.courseComponentPage,
                  size: 10,
                  offset: (state.courseComponentPage - 1) * 10
              }
          }
          let res = await getStuCourseHourList(data);
          // console.log(res);
          commit('SET_LIST', res.rows);
          commit('SET_TOTAL', res.total);
      },
      set_page({
          commit,
          dispatch
      }, data) {
          commit('SET_PAGE', data)
          dispatch('get_list')
      },
      set_size({
          commit,
          dispatch
      }, data) {
          commit('SET_SIZE', data)
          dispatch('get_list')
      },
  }
}
// 教师端课程管理
import {
    getOrganList,
    getCourseList
} from "../../request/teachCourseManage"

export default {
    namespaced: true,
    state: {
        listInfo: [], // 列表信息
        page: 1, // 页码数  --- 第几页
        size: 10, // 每页数量
        total: 0, //总条数
        isDragOrg: false, //是否可以拖拽机构列表
        orgOptions: null, //机构列表
        typeOptions: null, //课程类型列表
    },
    getters: {
        listInfo: state => state.listInfo,
        page: state => state.page,
        size: state => state.size,
        total: state => state.total,
        isDragOrg: state => state.isDragOrg,
        orgOptions: state => state.orgOptions,
        typeOptions: state => state.typeOptions,
    },
    mutations: {
        SET_ORGOPTIONS(state, data) {
            state.orgOptions = data
        },
        SET_ISDRAGORG(state, data) {
            state.isDragOrg = data
        },
        SET_TYPEOPTIONS(state, data) {
            state.typeOptions = data
        },
        SET_LIST(state, data) {
            state.listInfo = data
        },
        SET_PAGE(state, data) {
            state.page = data
        },
        SET_SIZE(state, data) {
            state.size = data
        },
        SET_TOTAL(state, data) {
            state.total = data
        },
    },
    actions: {
        async get_list({
            commit,
            state
        }) {
            let data = null;
            if (state.page == 1) {
                data = {
                    page: state.page,
                    size: state.size,
                    offset: 0
                }
            } else {
                data = {
                    page: state.page,
                    size: state.size,
                    offset: (state.page - 1) * state.size
                }
            }
            let res = await getCourseList(data);
            console.log(res);
            commit('SET_LIST', res.rows);
            commit('SET_TOTAL', res.total);
        },

        async get_orgAndtypeList({
            commit
        }) {
            let res = await getOrganList();
            console.log(res);
            commit('SET_ORGOPTIONS', res.data.org);
            commit('SET_TYPEOPTIONS', res.data.type);
            commit('SET_ISDRAGORG', false);
        },
        set_page({
            commit,
            dispatch
        }, data) {
            commit('SET_PAGE', data)
            dispatch('get_list')
            commit('SET_ISDRAGORG', false);
        },
        set_size({
            commit,
            dispatch
        }, data) {
            commit('SET_SIZE', data)
            dispatch('get_list')
            commit('SET_ISDRAGORG', false);
        },
    }
}
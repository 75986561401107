import {
    getInterGame
} from "../../request/interactGame"

export default{
    namespaced:true,
    state: {
        list:[],// 列表信息
        page: 1, // 页码数
        size: 10, // 每页数量    ------要与:page-sizes="[10, 20, 30, 40]"的数据第一项数量一致  否则会发生错误
        total: 0, //总条数
        interType: 7 // ai互动游戏的的type
    },
    getters: {
        list:state=>state.list,
        page:state=> state.page,
        size:state=> state.size,
        total:state=> state.total,
        interType:state=> state.interType,
    },
    mutations: { //只能放同步操作
        SET_LIST(state, data) {
            state.list = data
        },
        SET_PAGE(state, data) {
            state.page = data
        },
        SET_SIZE(state, data) {
            state.size = data
        },
        SET_TOTAL(state, data) {
            state.total = data
        },
        SET_interType(state, data) {
            state.interType = data
        },
    },
    actions: { //可以放异步操作 
        async get_list({
            commit,
            dispatch,
            state
        }) {
            let data={
                type:state.interType,
                page:state.page,
                row:state.size
            }
            let res = await getInterGame(data);
            commit('SET_LIST',res.data.rows);
            commit('SET_TOTAL',res.data.total);
        },
        set_page({ commit,dispatch }, data){
            commit('SET_PAGE',data)
            dispatch('get_list')
        },
        set_size({ commit, dispatch},data){
            commit('SET_SIZE', data)
            dispatch('get_list')
        },
    },
};
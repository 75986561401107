/** 客户管理 -- 销售管理 */
import $axios from "../common/http";

/**   @  获取销售人员列表
 * 
 */
export function getSaleUserList(data) {
    let res = $axios.get(`S9vC7rYOo4.php/user/user/index?page=${data.page}&row=${data.size}&op={"type":"="}&filter={"type":"3"}`);
    return res;
}
/**   @  查询销售人员列表
 * 
 */
export function searchSale(data) {
    let res = $axios.get(`S9vC7rYOo4.php/user/user/index?filter={"type":"3","mobile":${data}}&op={"type":"=","mobile":"like"}`);
    return res;
}
/**   @ 添加销售人员
 * 
 */
export function addSaleUser(data) {
    // ?mobile=${data.mobile}&nickname=${data.nickname}
    let res = $axios.post(`S9vC7rYOo4.php/user/user/register`, data);
    return res;
}
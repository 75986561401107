// 教学端用户管理 - - 机构认证
import $axios from "../common/http";

/**   @ info/check/index   申请注册机构的列表
 * id         status ：审核状态 0 待审核 1 通过 2 不通过    
 */
export function getApplyList(data) {
    let res = $axios.get(`S9vC7rYOo4.php/info/check/index?page=${data.page}&limit=${data.size}&offset=${data.offset}`);
    return res;
}
/**   @ info/check/edit  对注册机构进行审核
 * id  status ： 1 通过 2 不通过     content
 */
export function checkOrgan(data) {
    let res = $axios.post(`S9vC7rYOo4.php/info/check/edit`,data);
    return res;
}
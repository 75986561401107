//引入接口
import { getBotanyItem } from "../../../request/activeGame/planting"
export default {
    namespaced: true,
    state: {
        list: [], // 关卡列表
        offset: 1, // 页码数  --- 第几页
        limit: 10, // 每页数量
        total: 0, //总条数
    },
    getters: {
        planList: state => state.list,
        page: state => state.offset,
        size: state => state.limit,
        total: state => state.total,
    },
    mutations: {
        SETGAMELIST(state, payload) {
            state.list = payload.rows
        },
        SET_PAGE(state, data) {
            state.offset = data
        },
        SET_SIZE(state, data) {
            state.size = data
        },
        SET_TOTAL(state, data) {
            state.total = data
        },
    },
    actions: {
        async getBotanyItem({
            commit,
            state
        }) {
            let data = null;
            if (state.page == 1) {
                data = {
                    offset: state.offset,
                    limit: state.limit,
                }
            } else {
                data = {
                    offset: state.offset,
                    limit: state.limit,
                    offset: (state.offset - 1) * state.limit,
                }
            }
            let res = await getBotanyItem(data);
            console.log(res);
            commit('SETGAMELIST', res)
            commit('SET_TOTAL', res.total);
        },
        set_page({
            commit,
            dispatch
        }, data) {
            console.log(data);
            commit('SET_PAGE', data)
            dispatch('getBotanyItem')
        },
        set_size({
            commit,
            dispatch
        }, data) {
            commit('SET_SIZE', data)
            dispatch('getBotanyItem')
        },
        async get_listFilter({ commit }, payload) {
            // let res = await fifterLeveItem({ name: payload })
            // console.log(res);
            // commit('SETGAMELIST', res)
            // commit('SET_TOTAL', res.total);
        }
    }
}
/** 机构课时管理 */
import $axios from "../common/http";

/**   info/ppt/index   ppt列表   get
 *    @param{
 *          page  row
 *      }
 */
export function getPPTList(data) {
    let res = $axios.get(`S9vC7rYOo4.php/info/ppt/index?page=${data.page}&row=${data.row}`);
    return res;
}
/**   info/ppt/del   ppt删除   post
 *    @param{
 *          ids:pptId （可填数组）
 *      }
 */
export function delPPT(data) {
    console.log(data);
    let res = $axios.post(`S9vC7rYOo4.php/info/ppt/del`,data);
    return res;
}
// 教师端课程管理
import {
    getOrganList
} from "@/request/teachCourseManage";
export default {
    namespaced: true,
    state: {
        list: [], // 列表信息
        page: 1, // 页码数  --- 第几页
        size: 10, // 每页数量
        total: 0, //总条数
    },
    getters: {
        list: state => state.list,
        page: state => state.page,
        size: state => state.size,
        total: state => state.total,
    },
    mutations: {
        SET_LIST(state, data) {
            state.list = data
        },
        SET_PAGE(state, data) {
            state.page = data
        },
        SET_SIZE(state, data) {
            state.size = data
        },
        SET_TOTAL(state, data) {
            state.total = data
        },
    },
    actions: {
        async get_list({
            commit,
            state
        }) {
            let res = await getOrganList();
            console.log(res);
            commit('SET_LIST', res.data.type);
            /** 返回的数据是对象（ 0：未分类    1：幼儿园课程 ）    elementUi中table组件要使用数组对象类型参数 */
            // let type = res.data.type;
            // let arr = [];
            // for (const i in type) {
            //     let obj = {};
            //     obj.id = i;
            //     obj.name = type[i];
            //     arr.push(obj);
            // }
        },
        set_page({
            commit,
            dispatch
        }, data) {
            commit('SET_PAGE', data)
            dispatch('get_list')
        },
        set_size({
            commit,
            dispatch
        }, data) {
            commit('SET_SIZE', data)
            dispatch('get_list')
        },
    }
}
/** 订单管理  --  商品管理 */
/** 活动管理 */
import $axios from "../common/http";

/**   @ info/goods/index  获取商品列表
 * 
 */
export function getGoodsList(data) {
    let res = $axios.get(`S9vC7rYOo4.php/info/goods/index?page=${data.page}&limit=${data.size}&offset=${data.offset}`);
    return res;
}
/**   @ info/goods/add  添加商品列表
 * 
 */
export function addGoodsItem(data) {
    let res = $axios.post(`S9vC7rYOo4.php/info/goods/add`, data);
    return res;
}
/**   @ info/goods/index  获取单个商品列表
 * 
 */
export function getGoodsItem(data) {
    let res = $axios.get(`S9vC7rYOo4.php/info/goods/edit?id=${data}`);
    return res;
}
/**   @ info/goods/edit  修改商品列表
 * name 商品名称
origin 原价
price  价格
remark 描述
content  内容。   #传JSON字符串
 */
export function editGoodsItem(data) {
    let res = $axios.post(`S9vC7rYOo4.php/info/goods/edit`, data);
    return res;
}
// /**   @ info/goods/del  删除商品列表   不让删除了
//  * 
//  */
// export function delGoodsItem(data) {
//     let res = $axios.post(`S9vC7rYOo4.php/info/goods/del?ids=${data}`);
//     return res;
// }
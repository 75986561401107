<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  data() {
    return {};
  },
};
</script>

<style>
#teacherManage {
  width: 100%;
  overflow-x: hidden !important;
  padding: 0 20px;
}
.tabulation{
  margin-top: 20px;
}
</style>
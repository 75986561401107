/** 教师端用户管理 - - 消息管理 */
import $axios from "../common/http";

/**   @ activity/notice/index  获取消息列表
 * 
 */
export function getNoticeList(data) {
    let res = $axios.get(`S9vC7rYOo4.php/activity/notice/index?page=${data.page}&limit=${data.size}&offset=${data.offset}`);
    return res;
}
/**   @ activity/notice/add  添加消息列表
 * 
 */
export function addNoticeItem(data) {
    let res = $axios.post(`S9vC7rYOo4.php/activity/notice/add`, data);
    return res;
}
/**   @ activity/notice/index  获取单个消息列表
 * 
 */
export function getNoticeItem(data) {
    let res = $axios.get(`S9vC7rYOo4.php/activity/notice/edit?id=${data}`);
    return res;
}
/**   @ activity/notice/edit  修改消息列表
 * 
 */
export function editNoticeItem(data) {
    let res = $axios.post(`S9vC7rYOo4.php/activity/notice/edit`, data);
    return res;
}
/**   @ activity/notice/del  删除消息列表
 * 
 */
export function delNoticeItem(data) {
    let res = $axios.post(`S9vC7rYOo4.php/activity/notice/del?ids=${data}`);
    return res;
}
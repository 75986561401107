/** 客服 */
import $axios from "../../common/http";
/**   @ 获取学生课程类型
 * 
 */
export function getStudentCourseCategory(data) {
    let res = $axios.get(`S9vC7rYOo4.php/client/type/index?page=${data.page}&limit=${data.size}`);
    return res;
}
/**   @ 添加学生课程类型
 * 
 */
export function addStudentCourseCategory(data) {
    let res = $axios.post(`S9vC7rYOo4.php/client/type/add`, data);
    return res;
}
/**   @ 修改学生课程类型
 * 
 */
export function editStudentCourseCategory(data) {
    let res = $axios.post(`S9vC7rYOo4.php/client/type/edit`, data);
    return res;
}
/**   @ 删除学生课程类型
 * 
 */
export function delStudentCourseCategory(data) {
    let res = $axios.post(`S9vC7rYOo4.php/client/type/del`, data);
    return res;
}

export default {
    namespaced: true,
    state: {
        list: [], // 课时列表信息
    },
    getters: {
        list: state => state.list,
    },
    mutations: {
        SET_LIST(state, data) {
            state.list = data
        },
    },
    actions: {
        set_size({
            commit,
            dispatch
        }, data) {
            commit('SET_SIZE', data)
            dispatch('get_list')
        },
    }
}